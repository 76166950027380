.wrapperList{
    .itemList{
        padding:5px;
        color:$blue;
        cursor:pointer;
        svg{
            width: 1.5rem;
            margin-right: 10px;
            color:$blue;
        }
        &:hover{
            background: $orange;
            color:#ffffff;
            svg{
                color:#ffffff;
            }
        }
        &.active{
            background: $orange;
            color:#ffffff;
            svg{
                color:#ffffff;
            }
        }
    }
}