.card-custom {
    box-shadow: 0 3px 20px rgb(0 0 0 / 10%);
    position: relative;
    border-radius: 0.375rem;
    border-color: transparent;
    --tw-bg-opacity: 1;
    background-color:#ffffff;
    width: 100%;

}
.card-green{
    background: #47ae4c;
    color: #ffffff;
    svg,a{
        color:#ffffff;
    };
    &:hover
    {
        background: #ff5c00;
        a{
            color:#ffffff;
        }
    }

}
.w-10{
    width: 1.2rem;
}
.h-10 {

    height: 1.2rem;

}
.w-20{
    width: 2rem;
}
.h-20 {

    height: 2rem;
}
.w-30{
    width: 3rem;
}
.h-30 {

    height: 3rem;
}
.font-8{
    font-size: 0.85rem;
}
.font-10{
    font-size: 1.125rem;
}
.font-20{
    font-size: 1.6rem;
}

.font-medium {
    font-weight: 600;
}

.btn-icon {
   background-color: #3278b2;
    color: #ffffff !important;
    font-size: 0.7rem;
    font-weight: 700;
    line-height: 0.8;
    padding: 5px 10px;
    text-transform: capitalize;
    border-radius: 1px;
    letter-spacing: 0.5px;
    border: 0px !important;
    cursor: pointer;
    text-transform: uppercase;
    text-decoration: none;
    text-align: left;
    position: relative;

    svg {
        width: 30px;
        height: 30px;
    }
    &.delete{
        background-color:#ae0000!important;
    }
}

.separate{
    height:1px;
    background-color: rgba(204,204,204,.3);
    width:100%;
}
.icon{
    &.color{
        color:#ff5c00;
    }
}