main {
    position: relative;
    left: 120px;
    width: calc(100% - 120px);
    padding-left: 60px;
    padding-right: 20px;
}

.modalForm {
    .form-group {
        input[type="text"] {
            width: 100%;
            height: 40px;
            padding: 10px 10px;
        }
        input[type="email"] {
            width: 100%;
            height: 40px;
            padding: 10px 10px;
        }
    }
}

.sectionAction {
    margin: 20px 0px;
    border-top: 1px solid #e4e4e6;
    padding-top: 20px;
}

.selectEmployee {

    .iconTextEmployee {
        margin-right: 1rem;
        border-radius: 100%;
        width: 40px;
        height: 40px;

        display: table;
        text-align: center;

        span {
            vertical-align: middle;
            display: table-cell;
            font-weight: 600;
            font-size: 1rem;
            color: #ffffff;
        }
    }
}

.sectionAction{
    .wrapperActionIcon{
        width:40px;
        height:40px;
    }
    .action{
        margin:0px 5px;
        img{
            width:100%;
        }
        &.dropdown-toggle::after{
            display:none;
        }
    }
}
.navTabCustom{
    .nav-item{
        border-top-left-radius: 0.375rem;
        border-top-right-radius: 0.375rem;
        z-index:5;
        border: 1px solid #adb5bd!important ;
        border-bottom:0px solid;
        background: #e5e3e1;

        button{
            color: #212529;
        }
        &.active{
            background-color: #ffffff;
            box-shadow: 0 -8px 16px -3px rgb(0 0 0 / 10%);
            border: 0px solid !important;
        }
    }

}

.card-custom {
    box-shadow: 0 3px 20px rgb(0 0 0 / 10%);
    position: relative;
    border-radius: 0.375rem;
    border-color: transparent;
    --tw-bg-opacity: 1;
    background-color: #ffffff;
    width: 100%;

    &.contact {
        .card-body {
            padding: 40px;
        }
        .header-card {
            .icon {
                width: 70px;
                height: 70px;
                svg {
                    width: 100%;
                }
            }
            .option {
                .icon {
                    margin-right: 0px;
                    width: 35px;
                    height: 35px;
                    cursor: pointer;
                }
                .dropdown-menu {
                    .dropdown-item {
                        padding: 0.25rem 1rem;
                    }
                }
            }
        }
        .selectEmployee {

            margin: 20px 0px;
            border-top: 1px solid #e4e4e6;
            padding-top: 20px;

        }
        .sectionContact{
            margin: 20px 0px;
            border-top: 1px solid #e4e4e6;
            padding-top: 20px;
        }
        .sectionInteressi{
            margin: 20px 0px;
            border-top: 1px solid #e4e4e6;
            padding-top: 20px;
        }
        .list {
            .item {
                margin-bottom: 10px;
                .icon {
                    width: 25px;
                    height: 25px;
                    margin-right: 1rem;
                    svg {
                        width: 100%;
                    }
                }
            }
        }
    }
}

.btn_c {
    display: inline-flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    border-radius: 0.375rem;
    border-width: 1px;
    padding: 0.5rem 0.75rem;
    font-weight: 500;
    --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    transition-property: color, background-color, border-color, fill, stroke, opacity, box-shadow, transform, filter,
        -webkit-text-decoration-color, -webkit-backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow,
        transform, filter, backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow,
        transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.2s;
    &.box {
        box-shadow: 0 3px 20px #0000000b;
        position: relative;
        border-radius: 0.375rem;
        border-color: transparent;
        background-color: #ffffff;
    }
}

.header {
    background-image: url("../images/bg-image.jpg");
    width: 100%;
    height: 300px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    position: relative;
    .topTitle {
        text-align: center;
        position: relative;
        z-index: 2;
        h1 {
            color: #ffffff;
            font-weight: 700;
            font-size: 3rem;
        }
    }
    .overlay {
        background-color: rgba(0, 0, 0, 0.2);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
}

#map {
    width: 100%;
    height: 500px;
}

.container .card {
    width: 100%;
    background-color: #fff;
    position: relative;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    font-family: "Poppins", sans-serif;
    border-radius: 20px;
}
.container .card .form {
    width: 100%;
    height: 100%;

    display: flex;
}
.container .card .left-side {
    width: 35%;
    background-color: #304767;
    height: 100%;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    padding: 20px 30px;
    box-sizing: border-box;
}
/*left-side-start*/
.left-heading {
    color: #fff;
}
.steps-content {
    margin-top: 30px;
    color: #fff;
}
.steps-content p {
    font-size: 12px;
    margin-top: 15px;
}
.progress-bar {
    list-style: none;
    /*color:#fff;*/
    margin-top: 30px;
    font-size: 13px;
    font-weight: 700;
    counter-reset: container 0;
}
.progress-bar li {
    position: relative;
    margin-left: 40px;
    margin-top: 50px;
    counter-increment: container 1;
    color: #4f6581;
}
.progress-bar li::before {
    content: counter(container);
    line-height: 25px;
    text-align: center;
    position: absolute;
    height: 25px;
    width: 25px;
    border: 1px solid #4f6581;
    border-radius: 50%;
    left: -40px;
    top: -5px;
    z-index: 10;
    background-color: #304767;
}

.progress-bar li::after {
    content: "";
    position: absolute;
    height: 90px;
    width: 2px;
    background-color: #4f6581;
    z-index: 1;
    left: -27px;
    top: -70px;
}

.progress-bar li.active::after {
    background-color: #fff;
}

.progress-bar li:first-child:after {
    display: none;
}

/*.progress-bar li:last-child:after{*/
/*  display:none;  */
/*}*/
.progress-bar li.active::before {
    color: #fff;
    border: 1px solid #fff;
}
.progress-bar li.active {
    color: #fff;
}
.d-none {
    display: none;
}

/*left-side-end*/
.container .card .right-side {
    width: 65%;
    background-color: #fff;
    height: 100%;
    border-radius: 20px;
}
/*right-side-start*/
.main {
    display: none;
}
.active {
    display: block;
}
.main {
    padding: 40px;
}
.main small {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2px;
    height: 30px;
    width: 30px;
    background-color: #ccc;
    border-radius: 50%;
    color: yellow;
    font-size: 19px;
}
.text {
    margin-top: 20px;
}
.congrats {
    text-align: center;
}
.text p {
    margin-top: 10px;
    font-size: 13px;
    font-weight: 700;
    color: #cbced4;
}
.input-text {
    margin: 30px 0;
    display: flex;
    gap: 20px;
}

.input-text .input-div {
    width: 100%;
    position: relative;
}
.form-control:focus,
.form-select:focus {
    color: #212529;
    background-color: #fff;
    border-color: #e6e6e6 !important;
    outline: 0;
    box-shadow: none !important;
}
input[type="text"] {
    width: 100%;
    height: 40px;
    border: none;
    outline: 0;
    border-radius: 0px;
    border: 2px solid #e6e6e6;
    gap: 20px;
    box-sizing: border-box;
    padding: 25px 10px;
}
input[type="email"] {
    width: 100%;
    height: 40px;
    border: none;
    outline: 0;
    border-radius: 0px;
    border: 2px solid #e6e6e6;
    gap: 20px;
    box-sizing: border-box;
    padding: 25px 10px;
}
select {
    width: 100%;
    height: 40px;
    border: none;
    outline: 0;
    border-radius: 0px;
    border: 2px solid #e6e6e6;
    gap: 20px;
    box-sizing: border-box;
    padding: 25px 10px;
}
.input-text .input-div span {
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 14px;
    transition: all 0.5s;
}
/* .input-div input:focus ~ span,.input-div input:valid ~ span  {
top:-15px;
left:6px;
font-size:10px;
font-weight:600;
}

.input-div span{
top:-15px;
left:6px;
font-size:10px;
} */
.buttons {
    button {
        height: 40px;
        width: 100px;
        border: none;
        border-radius: 0px;
        background-color: #47ae4c;
        font-size: 13px;
        color: #fff;
        cursor: pointer;
        &:hover {
            background-color: #ffffff;
            color: #47ae4c;
            border: 2px solid #47ae4c;
        }
    }
}
.btn-custom {
    background-color: #5d7793;
    color: #fff;
    border: 2px solid #5d7793;
    &:hover {
        background-color: #ffffff;
        color: #5d7793;
        border: 2px solid #5d7793;
    }
}
.button_space {
    display: flex;
    gap: 20px;
}
.button_space button:nth-child(1) {
    background-color: #fff;
    color: #000;
    border: 1px solid#000;
}
.user_card {
    margin-top: 20px;
    margin-bottom: 40px;
    height: 200px;
    width: 100%;
    border: 1px solid #c7d3d9;
    border-radius: 10px;
    display: flex;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
}
.user_card span {
    height: 80px;
    width: 100%;
    background-color: #dfeeff;
}
.circle {
    position: absolute;
    top: 40px;
    left: 60px;
}
.circle span {
    height: 70px;
    width: 70px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #fff;
    border-radius: 50%;
}
.circle span img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}
.social {
    display: flex;
    position: absolute;
    top: 100px;
    right: 10px;
}
.social span {
    height: 30px;
    width: 30px;
    border-radius: 7px;
    background-color: #fff;
    border: 1px solid #cbd6dc;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    color: #cbd6dc;
}
.social span i {
    cursor: pointer;
}
.heart {
    color: red !important;
}
.share {
    color: red !important;
}
.user_name {
    position: absolute;
    top: 110px;
    margin: 10px;
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    width: 100%;
}
.user_name h3 {
    color: #4c5b68;
}
.detail {
    /*margin-top:10px;*/
    display: flex;
    justify-content: space-between;
    margin-right: 50px;
}
.detail p {
    font-size: 12px;
    font-weight: 700;
}
.detail p a {
    text-decoration: none;
    color: blue;
}

.checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #7ac142;
    fill: none;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
    display: block;
    stroke-width: 2;
    stroke: #fff;
    stroke-miterlimit: 10;
    box-shadow: inset 0px 0px 0px #7ac142;
    animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
}

.checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0;
    }
}
@keyframes scale {
    0%,
    100% {
        transform: none;
    }
    50% {
        transform: scale3d(1.1, 1.1, 1);
    }
}
@keyframes fill {
    100% {
        box-shadow: inset 0px 0px 0px 30px #7ac142;
    }
}

.warning {
    border: 1px solid red !important;
}
.containerRadio {
    width: 100%;
    input[type="radio"] {
        display: none;
        position: absolute;
    }
}
.checkmarkR {
    background: #fff;
    border-radius: 0px;
    border: #e6e6e6 2px solid;
    transition: all 0.3s linear;
    padding: 20px;
    h3 {
        font-size: 1.1rem;
        margin-right: 10px;
        margin-bottom: 0px;
    }
}
.containerRadio:hover input ~ .checkmarkR {
    background-color: #f7f7f7;
}

.containerRadio input:checked ~ .checkmarkR {
    border: #47ae4c 2px solid;
    -webkit-box-shadow: 0px 3px 6px 2px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 3px 6px 2px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 3px 6px 2px rgba(0, 0, 0, 0.16);
}
span.checkmarkR .square {
    width: 20px;
    height: 20px;
    border: 2px solid #e6e6e6;
}
.containerRadio input:checked ~ .checkmarkR .square {
    border: #47ae4c 2px solid;
    -webkit-box-shadow: 0px 3px 6px 2px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 3px 6px 2px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 3px 6px 2px rgba(0, 0, 0, 0.16);
    background: #47ae4c;
}

/*right-side-end*/
@media (max-width: 750px) {
    .container {
        height: scroll;
    }
    .container .card {
        max-width: 350px;
        height: auto !important;
        margin: 30px 0;
    }
    .container .card .right-side {
        width: 100%;
    }
    .input-text {
        display: block;
    }

    .input-text .input-div {
        margin-top: 20px;
    }

    .container .card .left-side {
        display: none;
    }
}

.cardTwo {
    width: 100%;
    min-height: 50vh;
    border: 10px solid #47ae4c;
    .headerCard {
        padding: 20px;
        color: #47ae4c;
        text-align: center;
        border-bottom: 2px solid;
        h3 {
            font-size: 35px;
            text-transform: uppercase;
        }
    }

    .content {
        padding: 20px;
        color: #47ae4c;

        .item {
            border-bottom: 1px solid;
            padding-top: 20px;

            p:first-child {
                color: #6c757d;
                margin-right: 50px;
                max-width: 200px;
                text-transform: capitalize;
                font-weight: 500;
                font-size: 15px;
            }
            p:last-child {
                font-size: 20px;
                font-weight: 800;
            }
        }
    }
    .contentImporto {
        padding: 20px;
        text-align: center;

        h6 {
            color: #47ae4c;
            font-weight: 500;
            font-size: 20px;
        }
        p {
            font-size: 45px;
            font-weight: 800;
            color: #47ae4c;
        }
    }
}
.cardOne {
    background: #47ae4c;
    padding: 30px;
    gap: 60px;
    color: #ffffff;
    .headerCard {
        h3 {
            text-transform: uppercase;
            font-size: 25px;
            margin-bottom: 30px;
        }
    }
    .imgResp {
        width: 100%;
        height: 100%;
    }
    .item {
        margin-bottom: 20px;
        p {
            color: #fff;
            text-transform: uppercase;
            font-weight: 500;
            margin-bottom: 0px;
        }
        p:first-child {
            font-size: 16px;
            opacity: 0.8;
        }
        p:last-child {
            font-size: 15px;
            font-weight: 700;
        }
    }
}

button.btn.btn-customSend {
    border: 1px solid;
    border-radius: 0px;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    margin-top: 10px;
    background: #47ae4c;
}

.wrapper-month {
    border-bottom: 1px solid #ced4da;
    padding: 2px;
}

.titleHeader {
    margin-left: 40px;
    line-height: 0;
    color: #fff;
    font-size: 20px;
    margin-top: 10px;
    h3{
        font-size: 1.5rem;
    }
}

.breaccumbs {
    ul{
        margin: 0px;
        padding: 0px;
        list-style: none;
    }

    li{
        display: inline;
        position: relative;
        padding: 0px 5px;
        color:$blue_500;
        font-weight:600;
        &::after{
            content: "/";
            position: absolute;
            padding-left:3px;

        }
        a {
            color:$blue_100;
            font-style: normal;
            text-decoration: none;
            font-size: 0.85rem;
        }
        &:hover{

            a{
                color:$orange;
            }
        }

    }
    li:last-child {
        &::after{
           display:none;

        }
        a{
            cursor: none;
            color:$blue_500;
            font-weight:600;
            font-size: 1rem;
        }
        &:hover{

            a{
                color:$blue_500;
            }
        }
    }
}