.wrapperListStages {

  }
  @media (min-width: 48em) {
    .wrapperListStages {
      max-width: 100%;
    }
  }

  .stepper__list {
    width: 100%;
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  @media (min-width: 48em) {
    .stepper__list {
      display: flex;
      justify-content: space-between;
    }
  }
  .stepper__list__item {
    padding: 3px 5px;
    text-align: center;
    position: relative;
    display: flex;
    align-items: center;
  }
  @media (min-width: 48em) {
    .stepper__list__item {
      padding: 10px;
      flex-direction: column;
      flex: 1;
    }
  }
  .stepper__list__item:after {
    content: "";
    display: block;
    position: absolute;
    z-index: 2;
  }
  @media (min-width: 48em) {
    .stepper__list__item:after {
      width: calc(100% - 100px);
      top: 28%;
      left: calc(50% + 50px);
      border-top: 2px dotted #E2DFDA;
    }
  }
  .stepper__list__item--done {
    color: #178A00;
    transition: all 0.1s;
  }
  .stepper__list__item--lost {

    .stepper__list__icon path{
      fill: #ae0000;
      color: #fff;
    }

    transition: all 0.1s;
  }

  @media (min-width: 48em) {
    .stepper__list__item--done:after {
      border-top-style: solid;
      border-top-width: 1px;
    }
  }
  .stepper__list__item--done:hover, .stepper__list__item--done:focus {
    text-decoration: underline;
    cursor: pointer;
  }
  .stepper__list__item--current {
    color: #006DFF;
  }
  .stepper__list__item--current:last-of-type:after, .stepper__list__item--current:only-of-type:after {
    height: 30%;
  }
  .stepper__list__item:last-of-type:after {
    display: none;
  }
  .stepper__list__item--pending {
    color: #807370;
  }
  .stepper__list__item--pending:after {
    height: 30%;
  }
  .stepper__list__title {
    margin: 1px 0 0;
  }
  @media (min-width: 48em) {
    .stepper__list__title {
      margin: 0;
    }
  }
  .stepper__list__icon {
    margin: 0 10px 0 0;
    height: 2em;
    width: 2em;
  }
  @media (min-width: 48em) {
    .stepper__list__icon {
      margin: 0 0 15px;
    }
  }
  .stepper__list__icon path {
    fill: currentColor;
  }
  .stepper__list__icon ellipse,
  .stepper__list__icon circle {
    stroke: currentColor;
  }

  .badge-stepper {
    background: #5d7793;
    padding: 1px 5px;
    font-size: 0.75rem;
    color: #ffffff;
}