// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~sweetalert2/src/sweetalert2.scss';

// Variables
@import 'variables';
@import '_style';
@import '_admin';
@import '_single';
@import '_nav';
@import '_header';
@import '_modal';
@import '_sidebar';
@import '_chance';
