.header-top{
    height: 75px;
    position: fixed;
    width: calc(100% - 2rem);
    top: 20px;
    left: 0px;
    right: 0;
    z-index: 100;
    border-radius: 0.75rem;
    margin: 0 auto;
    box-shadow: 0px 10px 10px rgb(0 0 0 / 15%);
    .nav-top{
        padding: 0px 40px;
        .logo-nav{
            position: relative;
            a{
                position: absolute;
                width:100%;
                height:100%;
                left:0px;
                top:0px;
            }
            img{
                width:150px;
            }
            .separate-v {
                position: absolute;
                right: -50px;
                width: 50px;
                height: 1px;
                background:$grey;
                transform: rotate(90deg);
                top: 20px;
            }
        }
    }
    .avatarHeader{
        .iconAvatar {
          /*   width: 50px;
            height: 50px;
            background: red;
            border-radius: 100%; */
            width: 2.5rem;
            height: 2.5rem;
            border-radius: 100%;
            color: #ffffff;
            vertical-align: middle;
            display: table;
            text-align: center;
            .iconText{
                vertical-align: middle;
                display: table-cell;
                font-weight: 600;
                font-size: 1rem;
            }
        }
        p{
            margin-bottom:0px;
            color:#ffffff;
        }
    }

    .dropdown-toggle::after {
        display: inline-block;
        margin-left: 0.855em;
        vertical-align: 0.255em;
        content: "";
        border-top: 0.5em solid;
        border-right: 0.5em solid transparent;
        border-bottom: 0;
        border-left: 0.5em solid transparent;
        color: #fff;
    }
}