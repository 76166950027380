// Body
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500&display=swap");

$body-bg: #ffffff;

// Typography
$font-family-sans-serif: "Rubik", sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;
$w-nav: 120px;

$blue: #5d7793;
$blue_100: #0e85c9;
$blue_500: #557089;
$black: #383b3d;
$grey: #e5e3e1;
$orange: #ff9520;
$red: #ae0000;

body {
    background-color: $grey;
}

.text-xs {
    font-size: 0.75rem;
}

.text-sm {
    font-size: 0.85rem;
}
.text-md {
    font-size: 1rem;
}
.text-lg {
    font-size: 1.125rem;
}
.text-xxl {
    font-size: 2.5rem;
}

.f-light-300 {
    font-weight: 300;
}
.f-regular {
    font-weight: 400;
}
.f-medium {
    font-weight: 500;
}

.color-primary {
    color: $blue;
}
.color-black {
    color: $black;
}

.color-green {
    color: #228b22 !important;
}
.color-orange {
    color: $orange
}

.color-svg-blue {
    color: $blue;
    fill: $blue;
}
.color-svg-green {
    color: #228b22 !important;
    fill: #228b22 !important;
}
.color-svg-blue_500 {
    color: $blue_500 !important;
    fill: $blue_500 !important;
}

.bk-primary {
    background-color: #5d7793 !important;
}
.bk-green {
    background-color: #228b22 !important;
}
.bk-red {
    background-color: #ae0000 !important;
}

.mt-7 {
    margin-top: 7rem;
}

.w-1 {
    width: 1rem;
}
.h-1 {
    height: 1rem;
}
.w-15 {
    width: 1.5rem;
}
.h-15 {
    height: 1.5rem;
}
.w-20 {
    width: 2rem;
}
.h-20 {
    height: 2rem;
}

.h_c-10 {
    height: 2.5rem;
}

.badge_c {
    padding: 3px 10px;
    border-radius: 5px;
    color: #ffffff;
    font-size: 0.8rem;
    font-weight: 500;

    &.badge_disabled {
        background-color: #fedb41;
        color: $blue;
    }
    &.badge_enable,
    &.badge_managed {
        background-color: #228b22;
    }
    &.badge_waiting {
        background-color: #fedb41;
        color: $blue;
    }
    &.badge_inactive {
        background-color: #e4e4e6;
        color: $blue;
    }
    &.badge_refused {
        background-color: #ae0000;
    }
}

.badge_c_outline {
    padding: 3px 10px;
    border-radius: 5px;
    color: #ffffff;
    font-size: 0.8rem;
    font-weight: 500;
    border: 2px solid;

    &.badge_open {
        border-color: #228b22;
        color: #228b22;
    }
    &.badge_lost {
        background-color: #ae0000;
        border-color: #ae0000;
    }
    &.badge_win {
        background-color: #228b22;
        border-color: #228b22;
    }
    &.badge_default {
        background-color: $blue_100;
        border-color: $blue_100;
    }
}

.border-right {
    border-right: 1px solid #e9ebec;
}
.circleIcon {
    background: $blue_500;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    display: block;
    margin-right: 10px;

    &.circle_active {
        background: #228b22;
    }
    &.circle_open {
        background: $blue_500;
    }
    &.circle_waiting {
        background: #fedb41;
    }
    &.circle_lost {
        background: #ae0000;
    }
}
.action-icon {
    margin: 0px 10px;
    cursor: pointer;

    svg {
        width: 1.3rem;
        color: $blue;
    }

    &.action-delete {
        svg {
            color: #ae0000;
        }
    }
}

.btn-check:checked + .btn-outline-primary,
.btn-check:active + .btn-outline-primary,
.btn-outline-primary:active,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show {
    color: #fff;
    background-color: $blue;
    border-color: $blue;
}
.btn-outline-primary {
    color: $blue;
    border-color: $blue;
}
.btn-check:focus + .btn-outline-primary,
.btn-outline-primary:focus {
    box-shadow: none !important;
}
.btn-outline-primary:hover {
    color: #fff;
    background-color: $blue_500 !important;
    border-color: $blue_500 !important;
}
.btn_cs {
    display: inline-flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    border-radius: 0.375rem;
    border-width: 1px;
    padding: 2px 20px;
    font-weight: 400;
    --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)),
        var(--tw-shadow);
    transition-property: color, background-color, border-color, fill, stroke, opacity, box-shadow, transform, filter,
        -webkit-text-decoration-color, -webkit-backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow,
        transform, filter, backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow,
        transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.2s;
}
.btn_text {
    background: transparent;
    border: 0px solid $black;
    border-bottom: 2px solid $black;
    box-shadow: none;
    border-radius: 0px;
}
.btn_primary {
    background-color: $orange;
    color: #ffffff;
    border: 1px solid $orange;
}

.btn_orange {
    background-color: $orange;
    color: #ffffff;
    border: 1px solid $orange;
    &:hover {
        background-color: #ffffff;
        color: $orange;
    }
    &:focus {
        box-shadow: none !important;
    }
}

.btn_delete {
    background-color: $red;
    color: #ffffff;
    border: 1px solid $red;
    &:hover {
        background-color: #ffffff;
        color: $red;
    }
    &:focus {
        box-shadow: none !important;
    }
    svg {
        width: 25px;
        height: 25px;
    }
}

.btn_outline_blue {
    background-color: #ffffff;
    color: $blue;
    border: 1px solid $blue;
    &:focus {
        box-shadow: none !important;
    }
    &:hover {
        background-color: $blue;
        color: #ffffff;
    }
}

.required:after {
    content: " *";
    color: red;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
    border-color: #dc3545 !important;
}

.table > :not(:first-child) {
    border-top: 2px solid transparent !important;
}

/*
.table  {
    border-spacing: 0 10px!important;
    border-collapse: separate!important;
    width:100%;
    .intro-x {
        position: relative;
        -webkit-animation: .4s intro-x-animation ease-in-out .33333s;
        animation: .4s intro-x-animation ease-in-out .33333s;
        -webkit-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
        -webkit-animation-delay: .1s;
        animation-delay: .1s;
        border-top-left-radius: 0.375rem!important;
        border-bottom-left-radius: 0.375rem!important;
    }
    td{
        border-bottom-width: 1px!important;
        padding: 0.75rem 1.25rem!important;
        background-color:#ffffff!important;
        box-shadow: 20px 3px 20px #0000000b!important;

        color: #000;
    }

} */
.table {
    .icon {
        width: 2.5rem;
        height: 2.5rem;
        .icon-svg {
            padding: 15px;
            svg {
                vertical-align: inherit;
            }
        }

        img {
            width: 100%;
            height: 100%;
        }
        &.iconImage {
            width: 50px;
            height: 50px;
        }
        &.iconText {
            width: 2.5rem;
            height: 2.5rem;
            border-radius: 100%;
            color: #ffffff;
            vertical-align: middle;
            display: table;
            text-align: center;
            span {
                vertical-align: middle;
                display: table-cell;
                font-weight: 600;
                font-size: 1rem;
            }
        }
    }
    .w-250 {
        width: 250px;
    }
    .w-350 {
        width: 350px;
    }
    .truncate {
        width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-transform: capitalize;
    }

    .actionIcon {
        .action {
            text-align:right;
            img {
                width: 35px;
                height: 35px;
                margin: 0px 5px;
            }
        }
    }
}
.separated {
    border-bottom: 1px solid #e4e4e6;
    padding-bottom: 10px;
}

._2Dmvc {
    align-items: center;
    border-top: 1px solid #e4e4e6;
    display: flex;
    padding: 6px 18px;
    width: 100%;
}
._3UM1B {
    border-top: 0px solid #e4e4e6;
}
._2ypgr {
    display: flex;
    justify-content: flex-start;
    overflow: hidden;
    padding-left: 0px;
    text-align: left;
    width: 100%;
}

//COMPONENT NOTE
#wrapperComment {
    .item {
        border-bottom: 1px solid #e4e4e6;
        padding: 10px 0px;
    }
}

//fullcalendar
.fc-col-header-cell-cushion {
    text-transform: uppercase;
    text-decoration: none;
    color: $blue !important;
}
.fc-daygrid-day-number {
    color: $blue !important;
    font-family: $font-family-sans-serif !important;
    text-decoration: none;
    font-weight: 600;
}
.fc-h-event {
    background: $blue_500 !important;
    border-color: $blue_500 !important;
    padding: 2px;
}

.fc-day-today {
    background: $grey !important;
    .fc-daygrid-day-number {
        color: $orange !important;
    }
}
.fc-event_meeting {
    background: #47ae4c !important;
    border-color: $blue_500 !important;
}
.fc-event-main svg {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    fill:#ffffff;
}

.header-top .notification.dropdown-toggle::after {
    display: none;
}
.notification {
    color: white;
    text-decoration: none;
    svg {
        width: 30px;
        height: 30px;
    }
}

.notification .badgeNotify {
    position: absolute;
    top: -7px;
    right: -3px;
    border-radius: 100%;
    background-color: #ff9520;
    color: white;
    width: 18px;
    height: 18px;
    font-size: 12px;
    text-align: center;
    line-height: 1.5;
}
.dropdownNotify {
    transform: translate(-20px, 50px) !important;
    width: 300px !important;
    padding: 10px;
    li {
        border-bottom: 1px solid #e4e4e6;
    }
    a {
        color: #557089;
        text-decoration: none;
        font-size: 0.85rem;
    }
}

//TAB

.accordion-items {
}
.accordion-heading {
    border-bottom: 1px solid #e7e7e7;
    padding: 15px 0px;
}
.accordion-heading:nth-last-child(2) {
    border-bottom: 0;
}

.accordion-heading:first-child:hover {
    border-radius: 3px 3px 0 0;
}
.accordion-heading:nth-last-child(2):hover {
    border-radius: 0 0 3px 3px;
}
.accordion-heading::before {
    content: "";
    vertical-align: middle;
    display: inline-block;
    float: left;
    transform: rotate(0);
    transition: all 0.5s;
    margin-top: 1px;
    margin-right: 5px;
    background-image: url("../images/arrow.svg");
    background-size: 20px 20px;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
}
.active.accordion-heading::before {
    transform: rotate(90deg);
}
.active.accordion-heading {
    border-bottom: 0px;
}
.not-active.accordion-heading::before {
    transform: rotate(0deg);
}
.accordion-content {
    display: none;
    padding: 15px;
    color: $blue;
    font-size: 13px;
    line-height: 1.5;
}

//BUTTON FILTER

.buttonFilter {
    border: 1px solid #adb5bd;
    background-color: transparent;
    min-width: 150px;
    padding: 20px;
    margin-right: 20px;
    z-index: 5;
    border-bottom: 0px;
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;

    &.active {
        background-color: #ffffff;
        border: 0px solid;
        box-shadow: 0 -8px 16px -3px rgb(0 0 0 / 10%);
    }
}

.toolBarFilter {
    background: #0e85c9;
    border-radius: 0.375rem;
    padding: 5px 10px;
    width: 100%;
    margin: 0 auto;

    .box_inputSearch{
        width: 180px;
        margin-right:8px;
        position: relative;
         i {
            position: absolute;
            background: #ffffff;
            padding: 0px 5px;
            color:#0e85c9;
            left:0;
            border-radius: 0.25rem;
        }

        .inputSearch{
            border:0px solid;
            height: 25px;
            width: 100%;
            padding: 10px 0px 10px 30px !important;
            font-size: 0.8rem;
            position: relative;
            border-radius: 0.25rem;
        }
    }
    .selectFilter {
        background-color: rgba(255, 255, 255, 0.7);
        padding: 0px 10px !important;
        height: 25px;
        width: 150px;
        margin: 0px 20px 0px 5px;
        font-size: 0.8rem;
    }
    .btn_orange {
        &.dropdown-toggle::after {
            display: none;
        }
    }
    .btn_outline {
        background-color:$blue;
        color:#ffffff;
        border: 1px solid #ffffff;
        &:focus {
            box-shadow: none !important;
        }
        &:hover {
            background-color: $blue;
            color: #ffffff;
        }
    }
}
.wrapper_filter_advance{
    background:#e5e3e1;
    border-radius: 0.375rem;
    padding: 5px 10px;
    width: 100%;
    margin: 15px auto 0px;

}

//radio image
.image-choice{
    div {
        border: 1px solid #ced4da;
        width: 50%;
        padding: 10px;
        border-radius: 0.375rem;
        position: relative;
        &.active{
            border: 2px solid $blue;
        }
        img{
            cursor: pointer;
            width: 35px;
        }
    }
}

.image-choice [type=radio] {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
  }

  //
  .accordion-button:not(.collapsed) {
    color:#ffffff;
    background-color: $blue;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}
.accordion-body ul {
    margin-left:0px;
    padding-left:0px;
}
.accordion-body li img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}
.accordion-body li {
    list-style-type:none;
    margin-bottom:15px;
}
textarea#editor {
    width: 600px;
}
@media (max-width: 991.98px) {
    textarea#editor {
        width: 100%
    }
}
