.nav_c{
    width:120px;
    height:100%;
    position: fixed;
    left: 0px;
    top: 0px;
    z-index:99;
    background-color: $blue_500;
    .all_items{
        position: relative;
        top: 100px;
        .item{
            position: relative;
            display: flex;
            width: 100%;
            text-align: center;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin: 20px 0px;
            padding:20px;
            color:#ffffff;
            a{
                position: absolute;
                top:0px;
                left:0px;
                width:100%;
                height:100%;
                color:#ffffff;
                z-index:3;
            }
            .icon_menu{
                width:25px;
                height:30px;
                display: block;
                z-index:2;
                margin-bottom: 3px;
                svg{
                    width:100%;
                    height:100%;
                    z-index:2;
                    fill: #ffffff;
                }
            }
            .text_c{
                display: block;
                z-index:2;
            }
            &.active,&:hover{
                position: relative;
                color:$black;
                &::after{
                    content: "";
                    position: absolute;
                    top: 0px;
                    right: 0px;
                    background:$grey;
                    width: 90%;
                    height: 100%;
                    z-index: 1;
                    border-top-left-radius: 15px;
                    border-bottom-left-radius: 15px;
                }
                svg{
                    fill:$black;
                }
                .text_c{
                    font-weight: 500;
                }
            }
        }
    }

}