.modal-header{
    background-color: $blue_500;
    .btn-close{
        color:#ffffff!important;
    }
    .iconModal {
        width: 40px;
        margin-right: 15px;
        border-right: 1px solid rgba(255,255,255,.3);
        padding-right: 15px;
        svg{
            color:$orange;
        }
    }

}
.modalForm{
    label{
        font-size: .85rem;
        font-weight: 500;
        color:$blue;
    }
    .form-control:focus, .form-select:focus{
        border:1px solid $blue_500!important;
    }
    input[type=text],input[type=email]{
        border: 1px solid #E6E6E6;
        font-size: 0.9rem;
    }

}

.modal-delete {
    padding: 50px;
    text-align: center;
    svg{
        margin: 0 auto;
    }
    h4{
        margin: 0 auto;
        font-size: 1.2rem;
        margin-bottom: 20px;
        font-weight: 500;

    }

    .btn{
        width: 200px;
        display: block;
        margin: 10px auto;
    }

}